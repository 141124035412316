<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Space ID Setting</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-text has-text-primary"
            tag="router-link"
            icon-left="plus"
            label="Add New User"
            to="/setting/user/create"
          />
        </div>
        <div class="level-right">
          <!-- <b-button icon-left="filter-variant" class="is-command">
            Filter
          </b-button> -->
          <b-input icon-right="magnify" rounded v-model="search">
            Search
          </b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <r-card>
        <b-button
          style="margin-bottom: 10px"
          v-if="showBtnSave"
          @click="saveChange"
        >
          Save Changes
        </b-button>
        <b-table
          :data="data ? data.data : []"
          aria-next-label="Next page"
          :loading="isLoading"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          @sort="onSort"
          :current-page="page"
          hoverable
        >
          <b-table-column field="no" label="No" v-slot="props">
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column field="email" sortable label="Email" v-slot="props">
            {{ props.row.user.email }}
          </b-table-column>
          <b-table-column field="role" label="Role" v-slot="props">
            <span style="text-transform: capitalize">{{ props.row.role }}</span>
          </b-table-column>
          <b-table-column label="Granted" v-slot="props">
            <span v-if="props.row.user.isActive">
              <b-switch
                @change.native="
                  changeGranted(
                    props.row.role,
                    props.row.companyUserServices[0].isActive,
                    props.row.companyUserServices[0].id
                  )
                "
                v-model="props.row.companyUserServices[0].isActive"
                :disabled="props.row.role == 'Owner'"
                type="is-success"
              ></b-switch>
            </span>
            <span v-else>-</span>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  No data yet, please contact us if you have any problems
                  (hello@getrise.id)
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </r-card>
      <router-view />
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      data: {
        meta: {},
        data: [],
      },
      switchGranted: [],
      showBtnSave: false,
      serviceID: 2,
      perPage: 10,
      sortField: 'created_at',
      sortOrder: 'desc',
      total: 0,
      page: 1,
      lastPage: 1,
      search: '',
      isLoading: false,
      delay: (function () {
        let timer = 0
        return function (callback, ms) {
          clearTimeout(timer)
          timer = setTimeout(callback, ms)
        }
      })(),
    }
  },
  created() {
    this.isLoading = true
    this.fetchData(
      this.serviceID,
      this.page,
      this.perPage,
      this.sortField,
      this.sortOrder,
      this.search
    )
    document.addEventListener('scroll', this.infiniteScroll)
  },

  computed: {},
  destroyed() {
    document.removeEventListener('scroll', this.infiniteScroll)
  },
  methods: {
    async fetchData(serviceID, page, perPage, orderBy, orderDirection, search) {
      await this.$store
        .dispatch('user/getUsers', {
          serviceID,
          page,
          perPage,
          orderBy,
          orderDirection,
          search,
        })
        .then((response) => {
          console.log(this.data.data)
          response.data.data.forEach((item) => {
            item.change = false
            this.data.data.push(item)
            // if (item.companyUserServices[0].isActive) {
            //   if (
            //     !this.switchGranted.includes(item.companyUserServices[0].id)
            //   ) {
            //     this.switchGranted.push(item.companyUserServices[0].id)
            //   }
            // }
          })
          this.lastPage = response.data.meta.lastPage
        })
        .catch((error) => {
          console.log(error)
        })

      this.isLoading = false
    },
    onSort(field, order) {
      this.data.data = []
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.isLoading = true
      this.fetchData(
        this.serviceID,
        this.page,
        this.perPage,
        this.sortField,
        this.sortOrder,
        this.search
      )
    },
    infiniteScroll() {
      this.delay(() => {
        if (this.page < this.lastPage) {
          if (
            window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 20
          ) {
            this.page = this.page + 1
            this.isLoading = true
            this.fetchData(
              this.serviceID,
              this.page,
              this.perPage,
              this.sortField,
              this.sortOrder,
              this.search
            )
          }
        }
      }, 1000)
    },
    async changeGranted(role, isActive, id) {
      if (role != 'Owner') {
        this.showBtnSave = true
        let map = this.switchGranted.map((item) => {
          return item.id
        })
        if (!map.includes(id)) {
          this.switchGranted.push({
            id,
            isActive,
          })
        } else {
          let findIndex = this.switchGranted.findIndex((item) => {
            return item.id == id
          })

          if (findIndex != -1) {
            this.switchGranted.splice(findIndex, 1, {
              id,
              isActive,
            })
          }
        }
        console.log(this.switchGranted)
      }
    },
    saveChange() {
      this.$buefy.dialog.confirm({
        title: 'Save Access Changes',
        message: 'Are you sure you want to save this change ?',
        confirmText: 'Yes',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.$store
            .dispatch('apps/updateCompanyUserService', this.switchGranted)
            .then(() => {
              this.$swal({
                icon: 'success',
                titleText: `Success Update User`,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'button is-success',
                },
              })
              this.showBtnSave = false
              this.data.data = []
              this.page = 1
              this.isLoading = true
              this.fetchData(
                this.serviceID,
                this.page,
                this.perPage,
                this.sortField,
                this.sortOrder,
                this.search
              )
            })
            .catch((error) => {
              console.log(error)
            })
        },
      })
    },
  },
  watch: {
    search(input) {
      this.data.data = []
      this.search = input
      this.page = 1
      this.isLoading = true
      this.delay(() => {
        this.fetchData(
          this.serviceID,
          this.page,
          this.perPage,
          this.sortField,
          this.sortOrder,
          this.search
        )
      }, 300)
    },
  },
}
</script>
